.generic-dialog-panel {
  max-width: 95vw !important;
  @apply w-full;

  @screen sm {
    @apply w-140;
  }

  @screen md {
    @apply w-160;
  }
}

.list-dialog-panel {
  width: 100vw !important;
  max-width: 100vw !important;
  height: 100vh !important;
  max-height: 100vh !important;
  @apply flex flex-auto rounded-none #{'!important'};

  @screen sm {
    max-width: 80vw !important;
    height: 95vh !important;
    max-height: 95vh !important;
    @apply rounded-2xl #{'!important'};
  }
}

.html-dialog-panel {
  width: 100vw !important;
  max-width: 100vw !important;
  height: 100vh !important;
  max-height: 100vh !important;
  @apply flex flex-auto overflow-hidden rounded-none #{'!important'};

  @screen md {
    max-width: 85vw !important;
    height: 95vh !important;
    max-height: 95vh !important;
    @apply rounded-2xl #{'!important'};
  }

  @screen lg {
    max-width: 55vw !important;
    height: 95vh !important;
    max-height: 95vh !important;
    @apply rounded-2xl #{'!important'};
  }
}

.tool-dialog-panel {
  max-width: 95vw !important;
  @apply w-full;

  @screen sm {
    @apply w-72;
  }

  @screen md {
    @apply w-90;
  }
}

.full-screen-dialog-panel {
  @apply flex flex-auto h-screen min-h-screen w-screen min-w-screen #{'!important'};

  .mat-dialog-container {
    @apply rounded-none #{'!important'};
  }
}

.e-biometrics-dialog-panel {
  @apply w-full;
  height: 90vh;
  max-width: 95vw !important;

  @screen lg {
    width: 1200px;
  }

  .mat-dialog-container {
    @apply p-0 #{'!important'};
  }
}
