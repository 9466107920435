.info-snackbar {
  @apply bg-blue-500 text-white text-base #{'!important'};
}

.success-snackbar {
  @apply bg-green-600 text-white text-base #{'!important'};
}

.error-snackbar {
  @apply bg-warn text-white text-base #{'!important'};
}

.mat-simple-snackbar-action { 
  @apply text-white #{'!important'};
}
