/* ----------------------------------------------------------------------------------------------------- */
/* @ Shepehered overrides
/* ----------------------------------------------------------------------------------------------------- */

.shepherd-header {
  @apply text-base bg-primary #{'!important'};

  .shepherd-title {
    @apply text-on-primary font-medium #{'!important'};
  }

  .shepherd-cancel-icon {
    > span {
      @apply text-on-primary #{'!important'};
    }
  }
}

.shepherd-text {
  @apply text-md #{'!important'};
}
