.fuse-highlight {
  pre {
    margin: 0 !important;
  }
}

.sc-highlight {
  height: 350px !important;
  background-color: #1e293b;
  border-radius: 0.25rem;
}
