/* ----------------------------------------------------------------------------------------------------- */
/* @ Reset styles
/* ----------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------------------------- */
/* @ Scrollbar
/* ----------------------------------------------------------------------------------------------------- */

* {
  &::-webkit-scrollbar-thumb {
    @apply bg-gray-400 dark:bg-gray-600 rounded #{'!important'};
  }

  &::-webkit-scrollbar-track {
    @apply bg-gray-300 dark:bg-gray-800 #{'!important'};
  }

  &::-webkit-scrollbar {
    @apply w-2 h-2 #{'!important'};
  }
}
