/* ----------------------------------------------------------------------------------------------------- */
/* @ Angular Material overrides
/* ----------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------------------------- */
/* @ Drawer
/* ----------------------------------------------------------------------------------------------------- */

.mat-drawer-inner-container {
  @apply flex flex-auto flex-col #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Paginator
/* ----------------------------------------------------------------------------------------------------- */

.mat-paginator {
  .mat-paginator-container {
    @apply justify-end py-0 #{'!important'};
  }
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Date range
/* ----------------------------------------------------------------------------------------------------- */

.mat-calendar-body-cell-content {
  @apply text-default #{'!important'};
}

.mat-date-range-input-separator {
  @apply text-hint #{'!important'};
}

.mat-calendar-body-selected {
  @apply bg-primary text-white #{'!important'};
}

.mat-calendar-body-in-range {
  .mat-calendar-body-cell-content {
    @apply bg-primary text-white #{'!important'};
  }
}

.mat-calendar-body-disabled {
  > .mat-calendar-body-cell-content {
    @apply text-disabled #{'!important'};
  }
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Drag
/* ----------------------------------------------------------------------------------------------------- */

.cdk-drag-preview {
  @apply box-border rounded-sm shadow-lg border-t border-b bg-primary-50 dark:bg-primary;
}

.cdk-drag-placeholder {
  @apply opacity-0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.signers-list.cdk-drop-list-dragging .signer-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
