/* ----------------------------------------------------------------------------------------------------- */
/* @ Syncfusion document editor overrides
/* ----------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------------------------- */
/* @ Body
/* ----------------------------------------------------------------------------------------------------- */

.e-de-ctn {
  @apply dark:bg-gray-900 dark:border-gray-700 border-opacity-40 #{'!important'};
}

.e-de-background {
  @apply bg-card #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Icons
/* ----------------------------------------------------------------------------------------------------- */

.e-toolbar .e-tbar-btn.e-btn .e-icons {
  @apply text-gray-500 dark:text-secondary #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Buttons
/* ----------------------------------------------------------------------------------------------------- */

.e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary {
  @apply bg-primary rounded-3xl py-1 px-4 border-none h-auto w-auto #{'!important'};
}

.e-btn.e-flat.e-primary:disabled, .e-css.e-btn.e-flat.e-primary:disabled {
  @apply bg-gray-500 bg-opacity-25 rounded-3xl py-1 px-4 border-none h-auto w-auto #{'!important'};
  .e-btn .e-btn-icon, .e-css.e-btn .e-btn-icon {
    @apply bg-gray-900 rounded-3xl py-1 px-4 border-none h-auto w-auto #{'!important'};
  }
}

.e-de-dlg-target .e-footer-content .e-control.e-btn.e-flat:not(.e-icon-btn):not(.e-primary),
.e-btn.e-flat:not(.e-icon-btn):not(.e-primary),
.e-css.e-btn.e-flat:not(.e-icon-btn):not(.e-primary) {
  @apply bg-transparent rounded-3xl py-1 px-4 h-auto dark:text-white border-gray-400 #{'!important'};
  border-width: 1px !important;
}

.e-toolbar .e-tbar-btn {
  @apply bg-transparent #{'!important'};
}

.e-button-custom {
  @apply p-0 #{'!important'};
}

/** Text button **/

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  @apply dark:text-secondary #{'!important'};
}

.e-toolbar .e-toolbar-items {
  @apply dark:bg-card #{'!important'};
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-overlay {
  @apply dark:bg-gray-900 #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Horizontal tool bar
/* ----------------------------------------------------------------------------------------------------- */

.e-hscroll .e-scroll-nav {
  @apply dark:bg-gray-900 #{'!important'};

  .e-icons {
    @apply dark:text-secondary #{'!important'};
  }
}

.e-hscroll .e-hscroll-content {
  @apply dark:bg-gray-900 #{'!important'};
}

.e-hscroll .e-scroll-nav.e-scroll-right-nav {
  @apply dark:border-gray-700 border-opacity-40 #{'!important'};
}

.e-hscroll .e-scroll-nav.e-scroll-left-nav {
  @apply dark:border-gray-700 border-opacity-40 #{'!important'};
}

.e-de-ctnr-properties-pane-btn .e-btn {
  @apply dark:bg-gray-900 text-primary border-b-0 border-t-0 border-r-0 #{'!important'};
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-separator {
  @apply dark:border-gray-700 border-opacity-40 #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Properties right pane
/* ----------------------------------------------------------------------------------------------------- */

.e-de-pane, .e-de-prop-separator-line {
  @apply dark:border-gray-700 border-opacity-40 #{'!important'};
}

.e-de-tool-ctnr-properties-pane {
  @apply dark:bg-gray-900 dark:border-gray-700 border-opacity-40 #{'!important'};
}

.e-de-ctnr-prop-label {
  @apply dark:text-secondary #{'!important'};
}

#footerupdatebuttonDiv {
  @apply bg-primary rounded-3xl py-1 px-4 border-none h-auto w-auto #{'!important'};
}

#footercancelbuttonDiv {
  @apply bg-transparent rounded-3xl py-1 px-4 h-auto dark:text-white border-gray-400 #{'!important'};
  border-width: 1px !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Properties left pane
/* ----------------------------------------------------------------------------------------------------- */

.e-de-op {
  @apply dark:bg-gray-900 dark:border-gray-700 border-opacity-40 #{'!important'};

  .e-de-op-header {
     @apply dark:text-secondary #{'!important'};
  }
}

.e-de-img-prty-span {
  @apply dark:text-gray-50 #{'!important'};
}

#close {
  @apply dark:text-white dark:hover:text-gray-50 #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Footer bar
/* ----------------------------------------------------------------------------------------------------- */

.e-de-status-bar {
  @apply dark:bg-gray-900 #{'!important'};

  .e-de-ctnr-pg-no-spellout {
    @apply dark:text-secondary #{'!important'};
  }
}


/* ----------------------------------------------------------------------------------------------------- */
/* @ Dialog
/* ----------------------------------------------------------------------------------------------------- */

.e-dialog {
  @apply bg-card rounded-xl border-none shadow-sm text-secondary #{'!important'};

  .e-dlg-header {
    @apply text-white #{'!important'};
  }
  .e-dlg-header *, .e-dlg-header-content, .e-dlg-content {
    @apply bg-primary dark:text-secondary border-none #{'!important'};
  }

  .e-btn .e-btn-icon.e-icon-dlg-close {
    @apply text-white #{'!important'};
  }

  .e-dlg-content, .e-footer-content {
    @apply bg-card border-none #{'!important'};
  }

  .e-label {
    @apply text-secondary #{'!important'};
  }

}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Inputs
/* ----------------------------------------------------------------------------------------------------- */

.e-input-group, .e-input-group.e-control-wrapper, .e-float-input, .e-float-input.e-input-group, .e-float-input.e-control-wrapper, .e-float-input.e-input-group.e-control-wrapper {
  @apply dark:bg-transparent dark:text-gray-100 #{'!important'};
}

.e-input-group .e-input-group-icon, .e-input-group.e-control-wrapper .e-input-group-icon {
  @apply text-secondary #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Checkbox
/* ----------------------------------------------------------------------------------------------------- */

.e-checkbox-wrapper .e-label, .e-css.e-checkbox-wrapper .e-label {
  @apply text-secondary #{'!important'};
}

#toc_checkboxDiv {
  @apply flex flex-col gap-3 p-2 #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Tabs
/* ----------------------------------------------------------------------------------------------------- */
.e-toolbar-item {
  @apply box-content #{'!important'};
}

.e-tab-wrap {
  @apply dark:hover:bg-gray-900 box-content #{'!important'};
}

.e-tab .e-tab-header .e-toolbar-item.e-active {
  @apply dark:border-gray-700 dark:bg-gray-900 border-opacity-40 #{'!important'};

  .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
    @apply dark:border-gray-700 dark:bg-gray-900 border-opacity-40 #{'!important'};
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text, .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  @apply text-primary dark:text-white #{'!important'};
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-text, .e-tab .e-tab-header .e-toolbar-item .e-tab-icon {
  @apply text-primary dark:text-white #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Labels
/* ----------------------------------------------------------------------------------------------------- */

.e-de-prop-sub-label, .e-de-prop-header-label {
  @apply dark:text-white #{'!important'};
}

.e-tab .e-content .e-item {
  @apply text-secondary #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Commentaries tab
/* ----------------------------------------------------------------------------------------------------- */

.e-de-review-pane {
  @apply dark:border-gray-700 border-opacity-40 #{'!important'};

  .e-toolbar {
    @apply dark:border-0 rounded-none dark:bg-transparent #{'!important'};
  }
}

.e-de-cmt-sub-container {
  @apply dark:bg-gray-900 dark:border-gray-400 dark:text-secondary dark:border-gray-700 border-opacity-40 #{'!important'};
}

