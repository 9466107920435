#signature-tab {
  .mat-tab-header {
    @apply -mb-2 px-6 #{'!important'};
  }

  .mat-tab-label-container {
    @apply px-0 #{'!important'};
  }

  .mat-tab-labels {
    @apply rounded-md border dark:border-gray-600 #{'!important'};

    .mat-tab-label {
      @apply flex flex-auto rounded-md max-w-1/4 #{'!important'};
    }
  }

  .mat-tab-body-wrapper {
    @apply flex-auto #{'!important'};

    .mat-tab-body {
      .mat-tab-body-content {
        @apply flex flex-auto #{'!important'};
      }
    }
  }
}

#person-tab {
  .mat-tab-header {
    @apply -mb-2 #{'!important'};
  }

  .mat-tab-group .mat-tab-body-content {
    @apply p-0 #{'!important'};
  }

  .mat-tab-label-container {
    @apply px-0 #{'!important'};
  }

  .mat-tab-labels {
    @apply rounded-md border dark:border-gray-600 #{'!important'};

    .mat-tab-label {
      @apply flex flex-auto rounded-md min-h-12 #{'!important'};
    }
  }

  .mat-tab-body-wrapper {
    @apply flex-auto #{'!important'};

    .mat-tab-body {
      .mat-tab-body-content {
        @apply flex flex-auto p-0 #{'!important'};
      }
    }
  }
}

.signature-wrapper {
  @apply cursor-pointer;
  @apply w-full h-full absolute top-0 #{'!important'};
}

.signature-drag {
  touch-action: none;
  scroll-margin-top: 2.25rem;
  @apply w-32 h-16 hidden;
  @apply absolute p-2 items-center justify-center box-border border border-primary bg-primary-500 bg-opacity-30 backdrop-blur #{'!important'};
}

.signature-text {
  @apply text-primary-700 text-md text-center truncate #{'!important'};
}

.signature-corner {
  @apply absolute w-3 h-3 rounded-full bg-primary border-2 border-primary-400 #{'!important'};
}

.signature-actions {
  @apply absolute flex items-center bg-primary -top-9 -left-px #{'!important'};
}

.signature-button {
  @apply cursor-pointer hover:bg-primary-700 p-1 #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Template Signature
/* ----------------------------------------------------------------------------------------------------- */

.signature-wrapper-container {
  @apply flex w-full h-full -z-1 absolute justify-center;
}

.page-signature-wrapper {
  background-color: rgb(50, 50, 50, 0.4);
  @apply absolute #{!important};
}

.template-signature-drag {
  touch-action: none;
  @apply w-32 h-16 hidden;
  @apply absolute p-2 items-center justify-center box-border border #{'!important'};
}

.template-signature-text {
  @apply text-center text-md truncate #{'!important'};
}

.template-signature-corner {
  @apply absolute w-3 h-3 rounded-full border-2 #{'!important'};
}

.template-signature-actions {
  @apply absolute flex items-center -top-9 -left-px #{'!important'};
}

.signature-order {
  @apply px-2 border-r text-on-primary #{'!important'};
}

.signature-predefined {
  @apply p-2 text-md whitespace-nowrap text-on-primary #{'!important'};
}

.template-signature-button {
  @apply cursor-pointer p-1 #{'!important'};

  &:hover {
    filter: brightness(80%);
  }
}
