/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles
/* ----------------------------------------------------------------------------------------------------- */

/* FullCalendar */
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "~@fullcalendar/list/main.css";

/* Perfect scrollbar */
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

/* Quill */
@import "~quill/dist/quill.snow.css";

/** Document editor & PDF viewer */
@import '~@syncfusion/ej2-angular-base/styles/bootstrap.css';
@import '~@syncfusion/ej2-angular-documenteditor/styles/bootstrap.css';
@import '~@syncfusion/ej2-base/styles/bootstrap.css';
@import '~@syncfusion/ej2-buttons/styles/bootstrap.css';
@import '~@syncfusion/ej2-calendars/styles/bootstrap.css';
@import '~@syncfusion/ej2-documenteditor/styles/bootstrap.css';
@import '~@syncfusion/ej2-dropdowns/styles/bootstrap.css';
@import '~@syncfusion/ej2-icons/styles/bootstrap.css';
@import '~@syncfusion/ej2-inputs/styles/bootstrap.css';
@import '~@syncfusion/ej2-lists/styles/bootstrap.css';
@import '~@syncfusion/ej2-navigations/styles/bootstrap.css';
@import '~@syncfusion/ej2-popups/styles/bootstrap.css';
@import '~@syncfusion/ej2-splitbuttons/styles/bootstrap.css';
/*@import '~@syncfusion/ej2-angular-base/styles/material.css';
@import '~@syncfusion/ej2-angular-documenteditor/styles/material.css';
@import '~@syncfusion/ej2-base/styles/material.css';
@import '~@syncfusion/ej2-buttons/styles/material.css';
@import '~@syncfusion/ej2-calendars/styles/material.css';
@import '~@syncfusion/ej2-documenteditor/styles/material.css';
@import '~@syncfusion/ej2-dropdowns/styles/material.css';
@import '~@syncfusion/ej2-icons/styles/material.css';
@import '~@syncfusion/ej2-inputs/styles/material.css';
@import '~@syncfusion/ej2-lists/styles/material.css';
@import '~@syncfusion/ej2-navigations/styles/material.css';
@import '~@syncfusion/ej2-popups/styles/material.css';
@import '~@syncfusion/ej2-splitbuttons/styles/material.css';*/

/* Shepered */
@import '~shepherd.js/dist/css/shepherd.css'
