/* ----------------------------------------------------------------------------------------------------- */
/* @ PDF Viewer overrides
/* ----------------------------------------------------------------------------------------------------- */

ngx-extended-pdf-viewer {
  #viewerContainer {
    @apply bg-default top-13 #{'!important'};
  }

  #loadingBar {
    @apply bg-primary-300 border-b-0 #{'!important'};

    .progress {
      @apply bg-primary #{'!important'};
    }
  }

  .toolbar {
    @apply border-b-0 border-0 font-sans #{'!important'};
  }

  .zoom {
    @apply h-full #{'!important'};
  }

  .pdfViewer {
    .page {
      border-image: none !important;
      @apply rounded-lg border shadow mx-auto mb-8 #{'!important'};

      &:first-child {
        @apply mt-8 #{'!important'};
      }

      .canvasWrapper {
        @apply relative #{'!important'};

        canvas {
          @apply rounded-lg #{'!important'};
        }
      }
    }
  }

  .unverified-signature-warning {
    @apply hidden #{'!important'};
  }

  // #toolbarContainer, #toolbarSidebar, #sidebarContent {
  //   @apply bg-card #{'!important'};
  // }

  // #toolbarViewer {
  //   @apply h-12 #{'!important'};
  // }

  // #toolbarViewerLeft, #toolbarViewerMiddle, #toolbarViewerRight {
  //   @apply mt-2 #{'!important'};
  // }

  // #sidebarContainer {
  //   @apply border-r border-gray-200 dark:border-gray-600 #{'!important'};
  // }

  // #secondaryToolbar {
  //   @apply font-sans #{'!important'};
  // }

  // #scaleSelect, #pageNumber {
  //   @apply ml-3 rounded-md shadow border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-900 text-current #{'!important'};
  // }

  // #numPages {
  //   @apply font-sans text-current mr-2 #{'!important'};
  // }

  // #outlineView {
  //   @apply font-sans #{'!important'};

  //   .treeItem {
  //     > a {
  //       @apply text-current #{'!important'};
  //     }

  //     &.selected {
  //       > a {
  //         @apply text-primary dark:text-primary-400 font-bold  #{'!important'};
  //       }
  //     }
  //   }
  // }

  // #sidebarContent {
  //   @apply top-0 #{'!important'};
  // }

  // .toolbar {
  //   @apply border-b border-gray-300 dark:border-gray-600 font-sans #{'!important'};
  // }

  // .secondaryToolbar {
  //   @apply border rounded py-2 bg-white dark:bg-gray-900 border-gray-300 dark:border-gray-600 #{'!important'};
  // }

  // .toolbarButton {
  //   @apply flex items-center justify-center text-secondary p-0 rounded-md #{'!important'};

  //   &.toggled {
  //     @apply border rounded-md bg-gray-100 dark:bg-gray-600 border-gray-300 dark:border-gray-600 #{'!important'};
  //   }

  //   &.pdfSidebarNotification {
  //     &::after {
  //       @apply bg-primary #{'!important'};
  //     }
  //   }
  // }

  // .secondaryToolbarButton {
  //   &.toggled {
  //     @apply border rounded-md bg-gray-100 dark:bg-gray-600 #{'!important'};
  //   }

  //   > svg {
  //     @apply text-secondary #{'!important'};
  //   }

  //   > span {
  //     @apply text-current #{'!important'};
  //   }
  // }

  // .doorHangerRight {
  //   // &::before, &::after {
  //   //   @apply border-gray-300 dark:border-gray-600 #{'!important'};
  //   // }
  // }

  // .paging-left {
  //   @apply ml-3 #{'!important'};
  // }

  // .paging-left, .paging-right {
  //   @apply flex #{'!important'};
  // }

  // .thumbnail {
  //   @apply border rounded-md shadow-sm mx-2 mb-3 #{'!important'};

  //   &.selected {
  //     @apply border-primary border-2 #{'!important'};
  //   }
  // }

  // .thumbnailSelectionRing {
  //   @apply p-0 #{'!important'};
  // }

  // .thumbnailImage {
  //   @apply rounded-md #{'!important'};
  // }

  // .treeItemToggler {
  //   &::before {
  //     @apply text-secondary #{'!important'};
  //   }
  // }

  // .pdfViewer {
  //   @apply pt-3 #{'!important'};

  //   .page {
  //     border-image: none !important;
  //     @apply rounded-xl border shadow mx-auto mb-3 #{'!important'};

  //     .canvasWrapper {
  //       canvas {
  //         @apply rounded-xl #{'!important'};
  //       }
  //     }
  //   }
  // }

  // .unverified-signature-warning {
  //   @apply hidden #{'!important'};
  // }

  // .toggled {
  //   .mat-icon {
  //     @apply text-primary #{'!important'};
  //   }
  // }
}
